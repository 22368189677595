/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { memo, useState } from 'react';
import { bool, oneOf, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LINE_ITEM_CUSTOM_REFUND,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_UNITS,
  propTypes,
} from '../../util/types';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUpdateBookingMaybe from './LineItemUpdateBookingMaybe';
import LineItemYoungCustomerCommissionMaybe from './LineItemYoungCustomerCommissionMaybe';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemLongTermDiscountMaybe from './LineItemLongTermDiscountMaybe';
import LineItemCreditsMaybe from './LineItemCreditsMaybe';
import LineItemMasterCardPromoMaybe from './LineItemMastercardPromoMaybe';
import LineItemProcessingFee from './LineItemProcessingFee';
import LineItemProcessingFeeRefundMaybe from './LineItemProcessingFeeRefundMaybe';
import LineItemYoungCustomerCommissionMaybeRefund from './LineItemYoungCustomerCommissionRefundMaybe';
import LineItemUpdateBookingPeriod from './LineItemUpdateBookingPeriod';
import LineItemUnknownItemsRefundMaybe from './LineItemUnknownItemsRefundMaybe';
import LineItemNewCarDiscountMaybe from './LineItemNewCarDiscountMaybe';
import LineItemNewExcessReduction from './LineItemNewExcessReduction';
import LineItemNewExcessReduction2 from './LineItemNewExcessReduction2';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import LineItemDepositPrice from './LineItemDepositPrice';
import LineItemDownloadReceipt from './LineItemDownloadReceipt';
import LineItemPreviousBookingPeriod from './LineItemPreviousBookingPeriod';
import TripModificationPeriod from '../TripModificationPeriod/TripModificationPeriod';
import { Modal, ModificationCharges } from '../index';
import LineItemModifyTotal from './LineItemInitialTotal';
import LineItemOriginalTotal from './LineItemOriginalTotal';
import ExtraDurationCharges from '../ExtraDurationCharges/ExtraDurationCharges';
import { restoreTransaction } from '../../util/data';
import LineItemForDistance from './LineItemForDistance';
// import LineItemLateReturnFee from './LineItemLateReturnFee';
import LineItemLateReturn from './LineItemLateReturn';
import LineItemProtectionFee from './LineItemProtectionFee';
import { get } from 'lodash';
import LineItemsOld from './LineItemsOld';
import LineItemForDistanceFee from './LineItemForDistanceFee';

import css from './BookingBreakdownNew.css';
import LineItemInitialPaymentItemsMaybe from './LineItemInitialPaymentItemsMaybe';
import LineItemForDistanceCharges from './LineItemForDistanceCharges';
import LineItemForInconvenience from './LineItemForInconvenience';

export const BookingBreakdownNewComponent = memo(
  props => {
    const {
      parentTx,
      rootClassName,
      className,
      intl,
      userRole,
      transaction: rawTransaction,
      currentUser,
      doNotShowTotal,
      unitType,
      isOnListingPage = false,
      timeZone,
      hideBookingPeriod,
      checkoutStep,
      changeCheckoutStep,
      isAddons,
      isTripDetailsPage,
      totalAllTransactions,
      providerFeeAllTransactions,
      replaceTotal,
      replaceTotalText,
      isCheckoutPage,
      isDropOff,
      chargeForDistance,
      otherCharges,
      onManageDisableScrolling,
      lateReturnDuration,
    } = props;
    const [isOpenDetails, setIsOpenDetails] = useState(false);

    const onSetOpenDetails = () => setIsOpenDetails(prev => !prev);

    const isCustomer = userRole === 'customer';
    const isProvider = userRole === 'provider';
    const classes = classNames(rootClassName || css.root, className);
    let transaction = rawTransaction;

    const lastAcceptedTx = isCheckoutPage &&
      rawTransaction &&
      rawTransaction.attributes.protectedData.initialTx && {
        id: 'restored-tx-id',
        attributes: {
          ...rawTransaction.attributes.protectedData.initialTx.attributes,
          lineItems: JSON.parse(
            rawTransaction.attributes.protectedData.initialTx.attributes.lineItems
          ),
        },
      };

    let initialTransaction = restoreTransaction(
      lastAcceptedTx
        ? {
          ...lastAcceptedTx,
          listing: transaction.listing,
        }
        : null
    );

    console.log('Booking breakdown new Transaction >>', props.transaction);
    console.log('Booking breakdown new isDistanceCharged? >>', props.transaction.isDistanceCharged);
    console.log('Booking breakdown new line items >>', props.transaction.attributes.lineItems);

    console.log('Booking breakdown new line  CHARGE FOR DISTANCE >>', props.chargeForDistance);

    console.log('Booking breakdown new line  OTHER CHARGES >>', props.otherCharges);

    console.log('Booking breakdown new isDistanceChargingTransactions >>', get(props, 'transaction.attributes.protectedData.isDistanceCharge', false));


    // isDistanceCharged comes from initiate / initiate-speculate API, we are using that as a flag for distance charging breakdown.
    const isDistanceCharged = get(props, 'transaction.isDistanceCharged', false);


    // This is the flag to show or not show distance charging on Trip Cost Details Page
    const isDistanceChargingTransaction = parentTx ? get(props, 'parentTx.attributes.protectedData.isDistanceCharge', false) : get(props, 'transaction.attributes.protectedData.isDistanceCharge', false);

    console.log('Booking breakdown new isDistanceChargingTransaction >>', isDistanceChargingTransaction);
    

    // const LTM_MONTHLY_PRICE = 300000;
    // if (isLongTermRental) {
    //   transaction = changeLTMPrice(transaction, LTM_MONTHLY_PRICE);
    // }

    return (
      <div className={classes}>
        {isAddons ? null : (
          <>
            {initialTransaction && (
              <>
                <TripModificationPeriod transaction={transaction} />
                <ModificationCharges
                  intl={intl}
                  onOpenDetails={onSetOpenDetails}
                  transaction={transaction}
                />
              </>
            )}

            {/* Change Here */}
            {(isDistanceCharged || isDistanceChargingTransaction) && (
              <>
                <LineItemUnknownItemsMaybe
                  transaction={transaction}
                  isCustomer={isCustomer}
                  intl={intl}
                  isProvider={isProvider}
                />
                <LineItemUnknownItemsRefundMaybe
                  transaction={transaction}
                  intl={intl}
                  isProvider={isProvider}
                  isCustomer={isCustomer}
                />
              </>
            )}
            {isTripDetailsPage && !isDistanceChargingTransaction && (
              <>
                <LineItemsOld
                  transaction={transaction}
                  isCustomer={isCustomer}
                  intl={intl}
                  isProvider={isProvider}
                />
              </>
            )}
            {isTripDetailsPage && chargeForDistance && !otherCharges && (
              <>
                <LineItemForDistanceCharges
                  transaction={transaction}
                  isCustomer={isCustomer}
                  intl={intl}
                  isProvider={isProvider}
                />
                <LineItemForDistanceFee
                  transaction={transaction}
                  isCustomer={isCustomer}
                  intl={intl}
                  isProvider={isProvider}
                />
              </>
            )}
            {isTripDetailsPage && chargeForDistance && otherCharges && (
              <>
                <LineItemLateReturn
                  transaction={transaction}
                  isCustomer={isCustomer}
                  isProvider={isProvider}
                  intl={intl}
                  lateReturnDuration={lateReturnDuration}
                // isExplanationBreakdown={isEditTripPage}
                />
                <LineItemForInconvenience
                  transaction={transaction}
                  isCustomer={isCustomer}
                  isProvider={isProvider}
                  intl={intl}
                // isExplanationBreakdown={isEditTripPage}
                />
              </>
            )}
            {(isDistanceCharged || isDistanceChargingTransaction) && !isDropOff && (
              <>
                <LineItemForDistance
                  transaction={transaction}
                  isCustomer={isCustomer}
                  intl={intl}
                  isProvider={isProvider}
                />
              </>
            )}
            <LineItemCustomerCommissionMaybe
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
              isTripDetailsPage={isTripDetailsPage}
            />
            <LineItemCustomerCommissionRefundMaybe
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
            />
            <LineItemYoungCustomerCommissionMaybe
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
              isTripDetailsPage={isTripDetailsPage}
            />
            <LineItemYoungCustomerCommissionMaybeRefund
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
            />
            {/* New Protection fee for distance based breakdown */}
            {(isDistanceCharged || isDistanceChargingTransaction) && (
              <>
                <LineItemProtectionFee
                  transaction={transaction}
                  isCustomer={isCustomer}
                  isProvider={isProvider}
                  intl={intl}
                />
              </>
            )}
            <LineItemProcessingFeeRefundMaybe
              parentTx={parentTx}
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
            />
            <LineItemProviderCommissionMaybe
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
              isTripDetailsPage={isTripDetailsPage}
              providerFeeAllTransactions={providerFeeAllTransactions}
            />
            <LineItemProviderCommissionRefundMaybe
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
            />
            {/* Needs to be commented out for now; as on November 12, 2024 */}
            {/* {isDistanceCharged && (
                <>
                  <LineItemGST
                    transaction={transaction}
                    isCustomer={isCustomer}
                    isProvider={isProvider}
                    intl={intl}
                    // isExplanationBreakdown={isEditTripPage}
                  />
                </>
              )} */}

            {isTripDetailsPage ? null : (
              <LineItemUpdateBookingMaybe
                isProvider={isProvider}
                transaction={transaction}
                intl={intl}
              />
            )}
          </>
        )}

        {doNotShowTotal || (!isTripDetailsPage && initialTransaction) ? null : (
          <div>
            <LineItemNewExcessReduction
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
              unitType={unitType}
              isTripDetailsPage={isTripDetailsPage}
            />
            <LineItemNewExcessReduction2
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
              unitType={unitType}
              isTripDetailsPage={isTripDetailsPage}
            />
            {/* <LineItemNewHomeDeliveryAddOnMaybe
              transaction={transaction}
              isProvider={isProvider}
              isCustomer={isCustomer}
              intl={intl}
            />
            <LineItemNewExcessReduction
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
              unitType={unitType}
            /> */}
            {/* <LineItemNewFuelInclusionAddOnMaybe
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
            /> */}
            {/* {isAddons ? (
              <LineItemProcessingFee
                transaction={transaction}
                isCustomer={isCustomer}
                intl={intl}
                isAddons={isAddons}
              />
            ) : null}
            {isAddons ? (
              <LineItemProcessingFeeRefundMaybe
                transaction={transaction}
                isCustomer={isCustomer}
                intl={intl}
                isAddons={isAddons}
              />
            ) : null} */}
            <LineItemProcessingFee
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
              isTripDetailsPage={isTripDetailsPage}
              otherCharges={otherCharges}
            />
            <LineItemMasterCardPromoMaybe
              transaction={transaction}
              userRole={userRole}
              intl={intl}
            />
            <LineItemLongTermDiscountMaybe transaction={transaction} intl={intl} />
            <LineItemCreditsMaybe transaction={transaction} isProvider={isProvider} intl={intl} />
            <LineItemNewCarDiscountMaybe
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
            />
            <hr className={css.totalDivider} />
            <LineItemTotalPrice
              parentTx={parentTx}
              currentUser={currentUser}
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
              isAddons={isAddons}
              isTripDetailsPage={isTripDetailsPage}
              totalAllTransactions={totalAllTransactions}
              replaceTotalText={replaceTotalText}
              replaceTotal={replaceTotal}
            />
            {isCheckoutPage ? (
              <LineItemDepositPrice
                currentUser={currentUser}
                transaction={transaction}
                isProvider={isProvider}
                intl={intl}
                isAddons={isAddons}
                isCheckoutPage={isCheckoutPage}
                isTripDetailsPage={isTripDetailsPage}
              />
            ) : (
              ''
            )}
            {isTripDetailsPage && currentUser ? (
              <LineItemDownloadReceipt
                currentUser={currentUser}
                transaction={transaction}
                isProvider={isProvider}
                intl={intl}
                isAddons={isAddons}
                isTripDetailsPage={isTripDetailsPage}
              />
            ) : (
              ''
            )}
            {/* Hid addons functional */}
            {/* {checkoutStep === checkoutStepConfiguration.CHECKOUT_PAYMENT && (
              <div
                className={css.editAddOns}
                onClick={() => changeCheckoutStep(checkoutStepConfiguration.CHECKOUT_ADD_ONS)}
              >
                <span>
                  <img src={editIcon} alt="" />
                </span>
                Edit Add-ons
              </div>
            )} */}
          </div>
        )}

        {initialTransaction && (
          <Modal
            id="breakdownDetailsModal"
            isOpen={isOpenDetails}
            onClose={() => setIsOpenDetails(false)}
            containerClassName={css.modalContainer}
            onManageDisableScrolling={onManageDisableScrolling}
            closeButtonMessage={' '}
          >
            <h2 className={css.modalTitle}>Modification charges</h2>

            <div className={css.explanationBreakdownContainer}>
              <div className={css.explanationContainerTitle}>Modified trip charges</div>
              <LineItemUpdateBookingPeriod
                transaction={transaction}
                isCustomer={isCustomer}
                intl={intl}
                isProvider={isProvider}
                isExplanationBreakdown={true}
                isEditTripPage={true}
              />
              <LineItemForDistance
                transaction={transaction}
                isCustomer={isCustomer}
                intl={intl}
                isProvider={isProvider}
                isEditTripPage={true}
              />
              <LineItemCustomerCommissionMaybe
                transaction={transaction}
                isCustomer={isCustomer}
                intl={intl}
                isExplanationBreakdown={true}
              />
              <LineItemNewExcessReduction
                transaction={transaction}
                isCustomer={isCustomer}
                intl={intl}
                unitType={unitType}
                isExplanationBreakdown={true}
                isEditTripPage={true}
              />
              <LineItemNewExcessReduction2
                transaction={transaction}
                isCustomer={isCustomer}
                intl={intl}
                unitType={unitType}
                isExplanationBreakdown={true}
                isEditTripPage={true}
              />
              <LineItemYoungCustomerCommissionMaybe
                transaction={transaction}
                isCustomer={isCustomer}
                intl={intl}
                isExplanationBreakdown={true}
              />
              <LineItemProcessingFee
                transaction={transaction}
                isCustomer={isCustomer}
                isProvider={isProvider}
                intl={intl}
                isExplanationBreakdown={true}
              />
              <LineItemModifyTotal transaction={transaction} intl={intl} />
            </div>

            <div className={css.explanationBreakdownContainer}>
              <div className={css.explanationContainerTitle}>
                Original trip charges
                <span className={css.paidBanner}>Paid</span>
              </div>
              {/*We have hidden the display of the original section, as there are problems with counting line-items when we change the listing's price*/}

              {/*<LineItemPreviousBookingPeriod*/}
              {/*  transaction={initialTransaction}*/}
              {/*  isCustomer={isCustomer}*/}
              {/*  intl={intl}*/}
              {/*  isProvider={isProvider}*/}
              {/*  isEditTripPage={true}*/}
              {/*  isExplanationBreakdown={true}*/}
              {/*/>*/}
              {/*<LineItemCustomerCommissionMaybe*/}
              {/*  transaction={initialTransaction}*/}
              {/*  isCustomer={isCustomer}*/}
              {/*  intl={intl}*/}
              {/*  isEditTripPage={true}*/}
              {/*  isExplanationBreakdown={true}*/}
              {/*/>*/}
              {/*<LineItemNewExcessReduction*/}
              {/*  transaction={initialTransaction}*/}
              {/*  isCustomer={isCustomer}*/}
              {/*  intl={intl}*/}
              {/*  isEditTripPage={true}*/}
              {/*  isExplanationBreakdown={true}*/}
              {/*/>*/}
              {/*<LineItemNewExcessReduction2*/}
              {/*  transaction={initialTransaction}*/}
              {/*  isCustomer={isCustomer}*/}
              {/*  intl={intl}*/}
              {/*  isEditTripPage={true}*/}
              {/*  isExplanationBreakdown={true}*/}
              {/*/>*/}
              {/*<LineItemYoungCustomerCommissionMaybe*/}
              {/*  transaction={initialTransaction}*/}
              {/*  isCustomer={isCustomer}*/}
              {/*  intl={intl}*/}
              {/*  isExplanationBreakdown={true}*/}
              {/*/>*/}
              {/*<LineItemProcessingFee*/}
              {/*  transaction={initialTransaction}*/}
              {/*  isCustomer={isCustomer}*/}
              {/*  isProvider={isProvider}*/}
              {/*  intl={intl}*/}
              {/*  isExplanationBreakdown={true}*/}
              {/*/>*/}
              <LineItemOriginalTotal transaction={transaction} intl={intl} />
            </div>

            <ExtraDurationCharges transaction={transaction} intl={intl} />
          </Modal>
        )}
      </div>
    );
  },
  (prev, next) =>
    isEqual(
      pick(prev, ['userRole', 'transaction', 'currentUser', 'checkoutStep', 'parentTx']),
      pick(next, ['userRole', 'transaction', 'currentUser', 'checkoutStep', 'parentTx'])
    )
);

BookingBreakdownNewComponent.defaultProps = {
  rootClassName: null,
  className: null,
  doNotShowTotal: false,
  customRefundType: LINE_ITEM_CUSTOM_REFUND,
  hideUnitPrice: false,
  timeZone: 'Australia/Sydney',
};

BookingBreakdownNewComponent.propTypes = {
  rootClassName: string,
  className: string,
  transactionMode: bool,
  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  doNotShowTotal: bool,
  hideUnitPrice: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdownNew = injectIntl(BookingBreakdownNewComponent);

BookingBreakdownNew.displayName = 'BookingBreakdownNew';

export default BookingBreakdownNew;

const changeLTMPrice = (rawTransaction, LTM_MONTHLY_PRICE) => {
  let transaction = cloneDeep(rawTransaction);
  const lineItems =
    (transaction && transaction.attributes && transaction.attributes.lineItems) || [];
  let longTermRentalPrice, processFees, tripFees;
  let totalPrice = transaction.attributes.payinTotal;
  lineItems.forEach(lineItem => {
    if (lineItem.code === LINE_ITEM_UNITS) {
      longTermRentalPrice = lineItem.lineTotal;
    } else if (lineItem.code === LINE_ITEM_PROCESSING_FEE) {
      processFees = lineItem.lineTotal;
    } else if (lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION) {
      tripFees = lineItem.lineTotal;
    }
  });
  longTermRentalPrice.amount = LTM_MONTHLY_PRICE;
  processFees.amount = (LTM_MONTHLY_PRICE + tripFees.amount) * 0.04;
  totalPrice.amount = LTM_MONTHLY_PRICE + processFees.amount + tripFees.amount;
  return transaction;
};
