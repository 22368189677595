import React, { memo } from 'react';
import css from './WeGotYourBack.css'; // Changed to a modular CSS import
import { Column, Container, Row } from '../../components/Grids/Grid';
import classNames from 'classnames';
import imageSource from '../../util/imageSource';

const features = [
  {
    icon: imageSource.newMarketingLandingPage.setting2,
    title: 'Drive with confidence',
    description:
      'Comprehensive insurance up to $35M included in every trip so you can drive stress-free!',
  },
  {
    icon: imageSource.newMarketingLandingPage.likeTag,
    title: 'Affordable and easy',
    description: 'Ditch the painful car rental counter and save with Drive mate.',
  },
  {
    icon: imageSource.newMarketingLandingPage.people,
    title: 'Thousands of cars to choose',
    description:
      'Whether you’re planning a weekend away with friends or just a trip to the supermarket - We’ve got a car for everyone!',
  },
  {
    icon: imageSource.newMarketingLandingPage.likeDislike,
    title: 'Two-way reviews',
    description:
      'Both hosts and guests review, so you can see other guests’ reviews before choosing your car.',
  },
  {
    icon: imageSource.newMarketingLandingPage.securitySafe,
    title: 'Safe & trusted community',
    description:
      'Drive mate verifies all cars and hosts before allowing them to rent so you can rest easy knowing your car is good to go.',
  },
  {
    icon: imageSource.newMarketingLandingPage.support,
    title: '24 x 7 customer support',
    description:
      "Call us, chat with us, email us! With a dedicated support team, we're here to help round the clock!",
  },
];

const WeGotYourBack = memo(({openSignUpModal}) => {
  return (
    <div className={css.weGotYourBackSection}>
      {/* <Container> */}
        {/* <Row className={css.sectionTitle}>
          <Column large={12}> */}
            <h2 className={css.heading}>We've got your back</h2>
          {/* </Column>
        </Row> */}
        {/* <Row>
          <Column large={12}> */}
            <div className={css.featuresGrid}>
              {features.map((feature, index) => (
                <div key={index} className={css.featureCard}>
                  <div className={css.wrapper}>
                    <img className={css.icon} src={feature.icon} alt={feature.title} />
                    <h6 className={css.featureTitle}>{feature.title}</h6>
                  </div>

                  <p className={css.featureDescription}>{feature.description}</p>
                </div>
              ))}
            </div>
          {/* </Column>
        </Row> */}
        {/* <Row>
          <Column large={12}> */}
    
            <div className={classNames(css.btnWrapper, css.showDesktop)}>
              <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
                <img src={imageSource.newMarketingLandingPage.gift} alt="gift" /> Sign Up and Get $10 Free Credit
              </button>
            </div>
            <div  className={classNames(css.btnWrapper, css.showMobile)}>
              <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
                <img src={imageSource.newMarketingLandingPage.gift} alt="gift" />
                Sign Up and Get $10 Free Credit
              </button>
            </div>

          {/* </Column>
        </Row> */}
      {/* </Container> */}
    </div>
  );
});

export default WeGotYourBack;
