import React, { memo, useState } from 'react';
import css from './HowItWorks.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import classNames from 'classnames';
import { BROWSE_BUTTON_PLAY_VIDEO_ID, EVENT_BROWSE_SAWHTD } from '../../util/gtm/gtmConstants';
import { sendGAEvent } from '../../util/googleAnalytics';
import { Modal } from '../../components';
import imageSource from '../../util/imageSource';

const steps = [
  {
    title: 'Find and book the perfect car',
    description: 'Explore hundreds of nearby cars to suit a range of needs, budgets and lifestyles',
  },
  {
    title: 'Pickup the car',
    description: 'Go to the car’s location and unlock it with the Drive mate app',
  },
  {
    title: 'Get cruising',
    description: 'Start driving and enjoy your trip with peace of mind',
  },
  {
    title: 'Return the car',
    description: 'Drop off the car where you picked it up when your trip is done',
  },
  {
    title: 'Tell others about your experience',
    description: 'Share your review and help others decide their next trip!',
  },
];

const HowItWorks = memo(
  ({ openSignUpModal, onVideoModalOpen, onPushGTMSeeVideoEvent, onManageDisableScrolling }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenVideo = () => {
      onPushGTMSeeVideoEvent(EVENT_BROWSE_SAWHTD, BROWSE_BUTTON_PLAY_VIDEO_ID);
      sendGAEvent({
        eventCategory: 'Others',
        eventAction: 'See How To Video',
      });
      setIsOpen(true);
      onVideoModalOpen(true);
    };
    return (
      <div className={css.hiwSection}>
        {/* <Container> */}
        <Row className={css.sectionTitle}>
          <h2 className={css.heading}>How it works</h2>
        </Row>
        <div style={{ display: 'flex' }} className={css.showDesktop}>
          {/* Left Column: Image */}
          {/* <Column large={6}> */}
          <div style={{ width: '100%', maxHeight: '500px', position: 'relative' }}>
            {/* Main Image */}
            <img src={imageSource.newMarketingLandingPage.hiwImage} alt="How it works" className={css.image} />

            {/* Play Button */}
            <img
              src={imageSource.newMarketingLandingPage.play}
              alt="play-button"
              className={css.playBtn}
              onClick={handleOpenVideo}
              style={{
                cursor: 'pointer',
                width: '50px', // Adjust the size as needed
                height: '50px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                padding:'14px',
                backgroundColor:'rgba(0,0,0,0.25)',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
                textDecoration: 'none',
                border: '1.5px solid white',
                borderRadius:'50%',
              }}
            />
          </div>

          {/* </Column> */}

          {/* Right Column: Steps with Scroll */}
          {/* <Column large={6}> */}
          <div className={css.hiwList}>
            <div className={css.scrollable}>
              {steps.map((step, index) => (
                <div key={index} className={css.hiwItem}>
                  <div className={css.step}>
                    <div className={css.stepNumberWrapper}>
                      <span className={css.stepNumber}>Step {index + 1}</span>
                    </div>
                    <div className={css.stepContent}>
                      <h3 className={css.stepTitle}>{step.title}</h3>
                      <p className={css.stepDescription}>{step.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* </Column> */}
        </div>
        <Row className={css.showMobile}>
          {/* Left Column: Image */}
          {/* <Column large={12}>
            <div className={css.imageWrapper}>
              <img src="https://picsum.photos/745/588" alt="How it works" className={css.image} />
            </div>
          </Column> */}

<div style={{ width: '100%', maxHeight: '500px', position: 'relative' }}>
            {/* Main Image */}
            <img src={imageSource.newMarketingLandingPage.hiwImage} alt="How it works" className={css.image} />

            {/* Play Button */}
            <img
              src={imageSource.newMarketingLandingPage.play}
              alt="play-button"
              className={css.playBtn}
              onClick={handleOpenVideo}
              style={{
                cursor: 'pointer',
                width: '50px', // Adjust the size as needed
                height: '50px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                padding:'14px',
                backgroundColor:'rgba(0,0,0,0.25)',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
                textDecoration: 'none',
                border: '1.5px solid white',
                borderRadius:'50%',
              }}
            />
          </div>

          {/* Right Column: Steps with Scroll */}
          <Column large={12}>
            <div className={css.hiwList}>
              {steps.map((step, index) => (
                <div key={index} className={css.hiwItem}>
                  <div className={css.step}>
                    <div className={css.titleWrapper} >
                      <div className={css.stepNumberWrapper}>
                      <span className={css.stepNumber}>Step {index + 1}</span>
                      </div>

                      <div>
                      <p className={css.stepTitle}>{step.title}</p>
                      </div>
                    </div>
                    <div className={css.stepContent}>
                      <p className={css.stepDescription}>{step.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Column>
        </Row>

        {/* <Row>
          <Column large={12}> */}
        <div className={classNames(css.btnWrapper, css.showDesktop)}>
          <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
            <img src={imageSource.newMarketingLandingPage.gift} alt="gift" /> Sign Up and Get $10 Free Credit
          </button>
        </div>
        <div className={classNames(css.btnWrapper, css.showMobile)}>
          <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
            <img src={imageSource.newMarketingLandingPage.gift} alt="gift" />
            Sign Up and Get $10 Free Credit
          </button>
        </div>
        {/* </Column>
        </Row> */}
        {/* </Container> */}
        <Modal
          id="videoLandingpage"
          containerClassName={css.modalContainer}
          closeButtonClassName={css.modalCloseButton}
          contentClassName={css.contentModal}
          isOpen={isOpen}
          onClose={() => {
            const videoIframe = document.querySelector('#videoIframe');
            if (videoIframe) {
              const iframeSrc = videoIframe.src;
              videoIframe.src = iframeSrc;
            }
            setIsOpen(false);
            onVideoModalOpen(false);
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
     <div onClick={()=>setIsOpen(false)} className={css.closebtn} >X</div>
          <ModalVideo isOpen={isOpen} />
        </Modal>
      </div>
    );
  }
);

const ModalVideo = ({ isOpen }) => {
  return (
    <div className={css.videoContentWrapper}>
      {isOpen && (
        <iframe
          id="videoIframe"
          className={css.iframeVideo}
          allowFullScreen={true}
          src="https://www.youtube.com/embed/-fg9W0MZYuo"
        ></iframe>
      )}
    </div>
  );
};

export default HowItWorks;
