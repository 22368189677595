import React, { memo } from 'react';
import css from './CustomersReview.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import imageSource from '../../util/imageSource';

const reviews = [
  {
    description:
      '“Just recently joined Drive mate to borrow cars, there is a few cars local to me and from booking to pickup is quick!”',
    author: 'Ben Sherman',
    carImage: imageSource.newMarketingLandingPage.benCar,
  },
  {
    description:
      '“The app is really good, Support helped me with my questions. I rented a car and it was easy.”',
    author: 'Jonathan Totman',
    carImage: imageSource.newMarketingLandingPage.jonathanCar,
  },
  {
    description:
      '“The Drive mate team makes the experience of car borrowing relatively stress-free.”',
    author: 'Shareen Townsend',
    carImage: imageSource.newMarketingLandingPage.shareenCar,
  },
  {
    description:
      "“Manuel from support is great. He's professional & fast to respond. We’re very happy with the service”",
    author: 'Mitchell Atkinson',
    carImage: imageSource.newMarketingLandingPage.mitchellCar,
  },
];

const ReviewCard = ({ carImage, description, author }) => {
  return (
    <div className={css.reviewCard}>
      <img src={carImage} alt="car-image" className={css.carImage} />
      <div className={css.reviewContent}>
        <p className={css.reviewText}>{description}</p>
        <p className={css.reviewAuthor}>{author}</p>
        <div className={css.stars}>
          {[...Array(5)].map((_, index) => (
            <img key={index} src={imageSource.newMarketingLandingPage.yellowStar} alt="star-image" className={css.star} />
          ))}
        </div>
      </div>
    </div>
  );
};

const CustomersReview = memo(() => {
  return (
    <div className={css.customersReviewSection}>
      
       
          {/* <Column large={12}> */}
            <h2 className={css.heading}>See what others are saying</h2>
          {/* </Column> */}
     
          <div className={css.reviewSlider}>
            {reviews.map((review, index) => (
              <ReviewCard
                key={index}
                carImage={review.carImage}
                description={review.description}
                author={review.author}
              />
            ))}
          </div>
     
  
    </div>
  );
});

export default CustomersReview;
