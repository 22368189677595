import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { VERIFICATION_STATUS_VERIFIED } from '../../util/types';
import { PrimaryButton, SecondaryButton } from '../../components';
import { checkIsOldCar } from '../../util/dates';
import { getTransitButtonLabelId } from './transitionHelper';
import {
  isNeedCustomerDropOffAction,
  isNeedCustomerPickUpAction,
  isNeedProviderDropOffAction,
  isNeedProviderPickUpAction,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  txIWaitingConfirmPickup,
} from '../../util/transaction';

import css from './TripPanel.css';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import {
  EVENT_CONVERSION_BOOKING_DECLINED,
  EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED,
} from '../../util/conversions/conversionsConstants';
import TripInstructionsSlider from './TripInstructionsSlider';
import {
  nextPickStep,
  resetSteps,
  setPickUpStep,
} from '../../components/PickAndDropPanel/ProgressBar/ProgressBar.duck';
import { useDispatch, useSelector } from 'react-redux';
import DropOffCheckList from "./DropOffCheckList";
import LockBoxSection from "./LockBoxSection";
import { initiateDistanceTransaction, setInitialValues } from '../../containers/CheckoutPage/AfterDropOffPage.duck';
import axios from 'axios';
import AlertBox from "../AlertBox/AlertBox";
import {removeStateFromLocalStorage} from "../../util/localStorageHelper";
import { failedDistanceTransitions } from '../../containers/DistanceCharging/distanceChargingHelpers';
import {lockShuCar} from "../../util/notification";
import { get } from 'lodash';
import dayjs from 'dayjs';
require('dotenv').config();
const API_URL = `${process.env.REACT_APP_API_SERVER_URL}/api/pdf/fill-insurance-form`;

const fillInsuranceForm = ({
  userId,
  listingId,
  guestName,
  pickUpDate,
  dropOffDate,
  plateNumber,
  chasisNumber,
  isOldCar,
  transactionId,
}) => {
  return fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      listingId,
      guestName,
      pickUpDate,
      dropOffDate,
      plateNumber,
      chasisNumber,
      isOldCar,
      transactionId,
    }),
  });
};

const ButtonsSection = ({
  declineInProgress,
  declineButtonName,
  transaction,
  acceptInProgress,
  acceptSaleError,
  declineSaleError,
  acceptButtonName,
  currentUser,
  onDeclineSale,
  declineSaleGTM,
  onAcceptSale,
  acceptSaleGTM,
  currentPhotos,
  activePhotoSection,
  exteriorPhotos,
  selfieWithId,
  interiorPhotos,
  odometerPhotos,
  fuelGaugePhotos,
  pickupProviderPhoto,
  fuelReceiptPhotos,
  transitionPickUpMaybe,
  transitionMaybe,
  onTransit,
  showSaleButtons,
  isShuEnabledCar,
  showPickUpSection,
  transitInProgress,
  allowUserTransit,
  emptyPhoto,
  inputOdometerInvalid,
  isCustomer,
  isProvider,
  allTripPhotos,
  showDropOffSection,
  isPickUp,
  isDropOff,
  isDrivelahGo,
  onPushEventGTMPickUpDropOff,
  ownListing,
  onUploadInteriorPhotoToMetadata,
  interiorPhotoDlGo,
  uploadInteriorPhotoProgress,
  uploadInteriorSuccess,
  isPickupRequested,
  shouldShowPickUpSection,
  shouldShowDropOffSection,
  distanceFromPickUp,
  showStartTripStep,
  showStartTripButton,
  isNewPickUpDropSection,
  cleanlinessScore,
  isLimitedUser,
  transitionAdminMaybe,
  setShowViewDetails,
  showViewDetails,
  loading = false,
  showEndTrip,
  isLockboxActive,
  findDeviceByType,
  listing,
  lockBox,
  isPickUpByAdminButtonEnabled,
  lockBoxCode,
  history,
  isTimeToShowStartTripButton,
  onLockboxDataChange,
  pickUpOdometer,
  requirePickupLocation,
  requireDropoffLocation,
  currentRating,
  isUserLocationAvailable,
  dropOffSentAtByAdmin,
  timeZone,
  showDistanceChargingFailedError,
  customDropOffReadingByAdmin,
}) => {

  const dispatch = useDispatch();

  console.log('isDropOff >>', isDropOff);
  console.log('dropOffSentAtByAdmin >>', moment(dropOffSentAtByAdmin).toISOString());
  console.log('showEndTrip >>', showEndTrip);
  console.log('Buttons section > transaction >', transaction);
  console.log('Buttons section > lastTransition >', transaction?.attributes?.lastTransition);

  const [showDropOffChecklist, setShowDropOffChecklist] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showPickUpModal, setShowPickUpModal] = useState(false);

  const handleShowDropOffChecklist = () => {
    setShowDropOffChecklist(true);
  };
  const buttonsDisabled = acceptInProgress || declineInProgress;
  const customerPickUpAction = showPickUpSection && isCustomer && isNeedCustomerPickUpAction(transaction);
  const customerDropOffAction = showDropOffSection && isCustomer && isNeedCustomerDropOffAction(transaction);
  const providerPickUpAction = showPickUpSection && isProvider && isNeedProviderPickUpAction(transaction);
  const providerDropOffAction = showDropOffSection && isProvider && isNeedProviderDropOffAction(transaction);
  const customerPickUpInteriorAction = showPickUpSection && isCustomer && isDrivelahGo && isNeedCustomerDropOffAction(transaction);

  const validTransaction = transaction && transaction.customer;
  const acceptAllow =
    validTransaction &&
    (transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED ||
      transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
        VERIFICATION_STATUS_VERIFIED);

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const guestIdentityIsNotVerify = !acceptAllow ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;

  const showAlertMessage = () => {
    setShowAlert(false);

    setTimeout(() => {
      setShowAlert(true);
    }, 0);
  };


  const numberActivePhotoSection =
    activePhotoSection && activePhotoSection.filter(i => i === true).length;
  const isActivePhotoSection = isCustomer ? numberActivePhotoSection > 0 : true;
  const allCurrentTripPhotos = [...currentPhotos, ...allTripPhotos];
  const currentExteriorPhotos = allCurrentTripPhotos.filter(p => p.type === 'exteriorPhotos');
  const currentInteriorPhotos = allCurrentTripPhotos.filter(p => p.type === 'interiorPhotos');
  const currentOdometerPhotos = allCurrentTripPhotos.filter(p => p.type === 'odometerPhotos');
  const currentFuelGaugePhotos = allCurrentTripPhotos.filter(p => p.type === 'fuelGaugePhotos');
  const currentSelfieWithId = allCurrentTripPhotos.filter(p => p.type === 'selfieWithId');
  const currentFuelReceiptPhotos = allCurrentTripPhotos.filter((p) => p.type === 'fuelReceiptPhotos')

  console.log("allCurrentTripPhotos", allCurrentTripPhotos)
  let validPhotoSection = false;
  if (!isDrivelahGo && !isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 8)
    && (!isLimitedUser ? (currentInteriorPhotos && currentInteriorPhotos.length >= 1) : true)
    && (currentOdometerPhotos && currentOdometerPhotos.length >= 1)
    && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1)
  ) {
    validPhotoSection = true;
  }

  //based on new design
  if(!isDrivelahGo && isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 8)
    && (currentOdometerPhotos && currentOdometerPhotos.length >=1)
    && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >=1)
  ) {
    validPhotoSection = true;
  }


  if(isDrivelahGo && isPickUp && !isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 8)
    && (currentSelfieWithId && currentSelfieWithId.length >=1)) {
    validPhotoSection = true;
  }

  //based on new design
  if(isDrivelahGo && isPickUp && isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 8)) {
    validPhotoSection = true;
  }

  // if(isDrivelahGo && isPickupRequested
  //   && (currentOdometerPhotos && currentOdometerPhotos.length >=1)
  //   && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >=1)
  // ) {
  //   validPhotoSection = true;
  // }

  if(isLimitedUser && isDrivelahGo && isPickUp && !isNewPickUpDropSection &&
    currentExteriorPhotos && currentExteriorPhotos.length >= 8  &&
    currentOdometerPhotos && currentOdometerPhotos.length >= 1 &&
    currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1
  ) {
    validPhotoSection = true;
  }

  if (
    isDrivelahGo &&
    isDropOff &&
    currentExteriorPhotos && currentExteriorPhotos.length >= 8 &&
    (!isLimitedUser ? (currentInteriorPhotos && currentInteriorPhotos.length >= 1) : true) &&
    currentOdometerPhotos && currentOdometerPhotos.length >= 1 &&
    currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1
  ) {
    validPhotoSection = true;
  }

  if (
    isDrivelahGo &&
    showPickUpSection &&
    isDropOff &&
    (!isLimitedUser ? (currentInteriorPhotos && currentInteriorPhotos.length >= 1) : true) &&
    currentOdometerPhotos && currentOdometerPhotos.length >= 1 &&
    currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1
  ) {
    validPhotoSection = true;
  }

  if (isLimitedUser && isDropOff && !dropOffSentAtByAdmin) {
    validPhotoSection = false
  }

  if (isLimitedUser && dropOffSentAtByAdmin) {
    console.log('IF > (isLimitedUser && dropOffSentAtByAdmin) >', { odometerPhotos, customDropOffReadingByAdmin });

    // Default to true when the user is limited and drop-off sent by admin is provided.
    validPhotoSection = true;

    // Condition 1: Neither odometerPhotos nor customDropOffReadingByAdmin provided
    if (!(odometerPhotos.length !== 0) && !customDropOffReadingByAdmin) {
      validPhotoSection = true;
      console.log('Condition 1 > No photos or custom reading uploaded');
    }

    // Condition 2: Either odometerPhotos or customDropOffReadingByAdmin is provided
    if (((odometerPhotos.length !== 0) && !customDropOffReadingByAdmin) || (!(odometerPhotos.length !== 0) && customDropOffReadingByAdmin)) {
      validPhotoSection = false;
      console.log('Condition 2 > Either photo or custom reading uploaded');
    }

    // Condition 3: Both odometerPhotos and customDropOffReadingByAdmin provided
    if ((odometerPhotos.length !== 0) && customDropOffReadingByAdmin) {
      validPhotoSection = true;
      console.log('Condition 3 > Both photo and custom reading uploaded');
    }
  }

  const isHost = currentUser.id.uuid === transaction.provider.id.uuid;

  const isEligbleForStartCar =
    isShuEnabledCar &&
    (transitionMaybe === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE) ;

  let isStartCar = isHost && isEligbleForStartCar;

  if(isShuEnabledCar && !isHost && (transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
    transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED)) {
      isStartCar = true;
  }

  const isEligibleForPushConfig =
    isShuEnabledCar &&
    (transitionMaybe == TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE ||
      transitionMaybe == TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED ||
      transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
      transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED);
  const isShuCar = !isHost && isEligibleForPushConfig;
  const cleanlinessScoreData = cleanlinessScore || [];

  let isStopCar = ((TRANSITION_CONFIRM_DROP_OFF == transitionMaybe ) && isShuEnabledCar && isHost);

  if(isShuEnabledCar && !isHost && TRANSITION_REQUEST_DROP_OFF_DLGO == transitionMaybe) {
    isStopCar = true;
  }
  const getFirstRelevantPhotoNote = (data, type) => {
    const trip = isPickUp;
    for (let photoGroup of data) {
      for (let photo of photoGroup) {
        if (photo[type] && photo.isPickUp === trip) {
          return photo.note;
        }
      }
    }
    return null;
  };

  const isFailedDistanceTransition = lastTransition => failedDistanceTransitions.includes(lastTransition);

  const handlePrimaryButtonClick = (transaction, ownListing) => {
    console.log("Is start car", isStartCar)
    console.log("Is SHU PUSH CAR", isShuCar)
    console.log('transitionmaybe', transitionMaybe)
    console.log('transitionAdmin maybe', transitionAdminMaybe)

    const dataObject = {
      currentTransaction: transaction,
      pickupSentAt: isPickUp ? moment.tz(timeZone).toISOString() : null,
      dropoffSentAt: isDropOff
        ? isLimitedUser
          ? dropOffSentAtByAdmin
            ? moment.tz(dropOffSentAtByAdmin, timeZone).toISOString()
            : moment.tz(timeZone).toISOString()
          : moment.tz(timeZone).toISOString()
        : null,
      pickupFrom: isPickUp ? (isLimitedUser ? "Admin" : "Web") : null,
      dropoffFrom: isDropOff ? (isLimitedUser ? "Admin" : "Web") : null,
    };
    console.log('Handle DropOff Button Click >> dataObject >>', dataObject);
    onPushEventGTMPickUpDropOff(transaction, transitionMaybe);

    const getPreviousPhotos = (transaction) => {
      return transaction &&
      transaction.attributes &&
      transaction.attributes.protectedData &&
      transaction.attributes.protectedData.photoObjects  || []
    }

    const previousPhotos = getPreviousPhotos(transaction)

    console.log('Handle DropOff Button Click >> previousPhotos >>', previousPhotos);


    const getTypePhotos = (type) => {
      return (previousPhotos || []).find((photos) => {
        return (photos && photos.length && photos[0][type] )
      }) || []
    }

    const allPhotoKeys = ['exteriorPhotos', 'interiorPhotos', 'odometerPhotos', 'fuelGaugePhotos', 'pickupProviderPhoto', 'fuelReceiptPhotos']

    const sections = isProvider
      ? ['providerPickup']
      : ['exterior', 'interior', 'odometer', 'fuel'];

    if (!isProvider && !isPickUp){
      sections.push('fuelReceipt');
    }

    let currentPhotos = [];
    activePhotoSection.map((section, index) => {
      if (section === true) {
        if (sections[index] === 'exterior') {
          currentPhotos = [...currentPhotos, exteriorPhotos];
        }
        if (sections[index] === 'selfieWithId') {
          currentPhotos = [...currentPhotos, selfieWithId];
        }
        if (sections[index] === 'interior') {
          currentPhotos = [...currentPhotos, interiorPhotos];
        }
        if (sections[index] === 'odometer') {
          currentPhotos = [...currentPhotos, odometerPhotos];
        }
        if (sections[index] === 'fuel') {
          currentPhotos = [...currentPhotos, fuelGaugePhotos];
        }
        if (sections[index] === 'providerPickup') {
          currentPhotos = [...currentPhotos, pickupProviderPhoto];
        }
        if (sections[index] === 'fuelReceipt'){
          currentPhotos = [...currentPhotos, fuelReceiptPhotos]
        }
      }
    });

    const combinedPhotos = allPhotoKeys.map((photoKey) => {
        const previous =  getTypePhotos(photoKey);
        const current = currentPhotos.find((record) => record && record[0] && record[0][photoKey]) || []
        return [...previous , ...current]
    }).filter((record) => Boolean(record) && Boolean(record.length));
    const currentOdometerReading = getFirstRelevantPhotoNote(currentPhotos, 'odometerPhotos');
    const currentFuelGaugeReading = getFirstRelevantPhotoNote(currentPhotos, 'fuelGaugePhotos');

    if (isLimitedUser && !isDropOff) {
      if (isPickUp && (currentOdometerReading == null || currentOdometerReading == 0)) {
        setAlertMessage('Please enter the odometer reading');
        showAlertMessage()
        return;
      }
      if (isDropOff && currentOdometerReading <= pickUpOdometer) {
        setAlertMessage('Odometer reading cannot be less than at pickup.');
        showAlertMessage()
        return;
      }
      if (currentFuelGaugeReading == null || currentFuelGaugeReading == 0) {
        setAlertMessage('Fuel gauge reading is required.');
        showAlertMessage()
        return;
      }
    }

    const allPhotosDropOff = combinedPhotos;

    console.log('Handle DropOff Button Click >> allPhotosDropOff >>', allPhotosDropOff);

    console.log('Handle DropOff Button Click >> isDropOff >>', isDropOff);

    if (
      txIWaitingConfirmPickup(transaction) &&
      (transitionMaybe === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
      transitionMaybe === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
      transitionMaybe === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ) &&
      ownListing &&
      ownListing.attributes.publicData.insurance === 'private'
    ) {
      console.log('Handle DropOff Button Click >> IF >>');
      const { provider, customer, booking } = transaction;
       fillInsuranceForm({
        userId: provider.id.uuid,
        listingId: ownListing.id.uuid,
        guestName: customer.attributes.profile.displayName,
        pickUpDate: moment(booking.attributes.start).format('DD/MM/YYYY'),
        dropOffDate: moment(booking.attributes.end).format('DD/MM/YYYY'),
        plateNumber: ownListing.attributes.publicData.license_plate_number,
        chasisNumber: ownListing.attributes.privateData.chasisNumber,
        isOldCar: checkIsOldCar(ownListing),
        transactionId: transaction.id.uuid,
      })
        .then(res => res.json())
        .then(data =>
          {
          onTransit(
            transaction.id,
            isLimitedUser && transitionAdminMaybe ? transitionAdminMaybe: transitionMaybe,
            dataObject,
            [...allPhotosDropOff],
            data,
            isShuCar,
            isStartCar,
            null,
            null,
            null,
            null,
            isStopCar,
            cleanlinessScoreData,
            isDropOff,
            ).then(data => {
              removeStateFromLocalStorage(`retain_pickup_dropoff_state/${transaction.id.uuid}`);
              if (isCustomer && isPickUp && !isLimitedUser) {
                dispatch(nextPickStep());
              } else {
                shouldShowPickUpSection(false);
                shouldShowDropOffSection(false);
              }
            });
        }
      );
    } else {
      console.log('Handle DropOff Button Click >> ELSE >>');
      console.log('Handle DropOff Button Click >> ELSE >> props to go in ONTRANSIT >> ', isDropOff);
      onTransit(
          transaction.id,
          isLimitedUser && transitionAdminMaybe ? transitionAdminMaybe: transitionMaybe,
          dataObject,
          [...allPhotosDropOff],
          null,
          isShuCar,
          isStartCar,
          null,
          null,
          null,
          null,
          isStopCar,
          cleanlinessScoreData,
          isDropOff,
        ).then(data => {
           console.log('Handle DropOff Button Click >> on Transit >> .then response Data', data?.data?.data?.attributes?.lastTransition);

          //  Getting last transition of transaction
           const lastTransition = data?.data?.data?.attributes?.lastTransition;

          //  Checking if last transition is distance failed transition
           const distanceChargingFailed = isFailedDistanceTransition(lastTransition);

          if (distanceChargingFailed && distanceTravelled) {
            const currentUrl = window.location.href;
            const newUrl = `${currentUrl.split('/details')[0]}/distance-charge-failed`;

            console.log('Handle DropOff Button Click >> on Transit >> newUrl > ', newUrl);

            window.location.href = newUrl;
          }
          const apiData = {
            user_id: transaction.customer.attributes.profile.publicData.shuUserId,
            booking_id: transaction.id.uuid,
            listingId: transaction.listing.id.uuid,
            fleet_id: transaction.listing.attributes.publicData.license_plate_number,
          };
           console.log('is dropoff', isDropOff)

          if (isDropOff && isDrivelahGo) {
            lockShuCar(apiData).then(response => {
              console.log('Car got locked successfully:', response);
            }).catch(error => {
              console.error('Error in lockShuCar:', error);
            });
          }
          removeStateFromLocalStorage(`retain_pickup_dropoff_state/${transaction.id.uuid}`);
          if (isCustomer && isPickUp && !isLimitedUser) {
            dispatch(nextPickStep());
          } else {
            shouldShowPickUpSection(false);
            shouldShowDropOffSection(false);
          }
        });
    }

  };

  const handleLockBoxCar = () => {
    if(customerPickUpAction) {
      setShowPickUpModal(true)
    } else if(customerDropOffAction) {
      setShowDropOffChecklist(true)
    }
  }

  const submitFuelReceipt = async () => {
    if (!fuelReceiptPhotos || fuelReceiptPhotos.length === 0) {
      console.log('No fuel receipt photos');
      return;
    } else {
      console.log("Fuel receipt photos:", fuelReceiptPhotos);
    }

    const receiptUrl = fuelReceiptPhotos[0]?.fileUrl;
    if (!receiptUrl) {
      console.log('No fuel receipt url');
      return;
    } else {
      console.log("Fuel receipt url:", receiptUrl);
    }

    const data = {
      ticketTypeId: '10',
      email: currentUser?.attributes?.email,
      title: 'Trip Drop-Off with Fuel Receipt Submission',
      description:
        'The user dropped off their trip and submitted a fuel receipt for review. Please verify the receipt',
      user:
        currentUser?.attributes?.profile?.firstName +
        ' ' +
        currentUser?.attributes?.profile?.lastName,
      trip_id: transaction?.attributes?.booking?.uuid,
      amount: '', // Not defined yet
      image: receiptUrl,
      type: '10',
    };

    const ticket = async data => {
      try {
        console.log('Creating ticket with data: ', data);
        const response = await axios.post(
          process.env.REACT_APP_API_SERVER_URL + '/api/tickets/create-ticket',
          data
        );
        // const response = await axios.post(
        //   process.env.PORT + '/api/tickets/create-ticket',  // for local testing
        //   data
        // );

        console.log('Ticket created: ', response);
      } catch (error) {
        console.log('Error creating ticket: ', error);
      }
    };

    ticket(data);
  };

  const isDistanceCharge = get(transaction, 'attributes.protectedData.isDistanceCharge', false);
  const distanceTravelled = get(transaction, 'attributes.metadata.distanceTravelled', '');
  const isDistanceCharged = get(transaction, 'attributes.metadata.isDistanceCharged', false);
  const lastTransition = get(transaction, "attributes.lastTransition", '');

  console.log("Check the props passed on for disabling: ", !isPickUp, !showEndTrip, !showViewDetails, distanceFromPickUp, loading);

  return (
    <div className={`${css.buttonSectionContainer} ${isCustomer ? css.startTripBoxWrapper : ''}`} >
      <div className={`${css.buttonSectionInner} ${css.adjustButtonPadding}`}>

        {/* Showing the pay now button when transaction is distance charging transaction and distance travelled is present and distance charged is false */}
        {showDistanceChargingFailedError && (
          <>
            <PrimaryButton
              className={css.buttonAccepted}
              onClick={() => {
                const currentUrl = window.location.pathname;
                const baseUrl = currentUrl.split('/details')[0];
                const newUrl = `${baseUrl}/distance-charge-failed`;
                history.push(newUrl);
              }}
            >
              View details & Pay
            </PrimaryButton>
          </>
        )}
        {isProvider && showSaleButtons ? (
          <div className={css.buttonSectionText}>
            <span>
              Declining too many bookings can have a negative impact on your positioning on search.
            </span>
          </div>
        ) : null}

        {/* <div className={css.buttonSectionText}>
            {guestIdentityIsNotVerify}
            {acceptErrorMessage}
            {declineErrorMessage}
          </div> */}
        {isProvider && showSaleButtons ? (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              className={css.buttonDisabled}
              inProgress={declineInProgress}
              disabled={buttonsDisabled}
              id={declineButtonName}
              name={declineButtonName}
              onClick={() => {
                onDeclineSale(transaction.id);
                // const properties = createRawPropertiesForGTM({ props: currentUser });
                // createConversionEvents(properties, EVENT_CONVERSION_BOOKING_DECLINED, 'click');
                // declineSaleGTM();
              }}
            >
              <FormattedMessage id="TransactionPanel.declineButton" />
            </PrimaryButton>
            <SecondaryButton
              inProgress={acceptInProgress}
              disabled={buttonsDisabled || !acceptAllow}
              className={css.buttonAccepted}
              id={acceptButtonName}
              name={acceptButtonName}
              onClick={() => {
                if (acceptAllow) {
                  onAcceptSale(transaction.id, currentUser);
                  // const properties = createRawPropertiesForGTM({ props: currentUser });
                  // createConversionEvents(properties, EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED, 'click');
                  // acceptSaleGTM();
                }
              }}
            >
              <FormattedMessage id="TransactionPanel.acceptButton" />
            </SecondaryButton>
          </div>
        ) : null}

        {/*{customerPickUpInteriorAction &&*/}
        {/*!(interiorPhotoDlGo.length > 0) &&*/}
        {/*!uploadInteriorSuccess ? (*/}
        {/*  <div className={css.buttonSectionWrapper}>*/}
        {/*    <PrimaryButton*/}
        {/*      className={css.buttonAccepted}*/}
        {/*      inProgress={uploadInteriorPhotoProgress}*/}
        {/*      disabled={transitInProgress || !validPhotoSection || uploadInteriorPhotoProgress}*/}
        {/*      id={declineButtonName}*/}
        {/*      name={declineButtonName}*/}
        {/*      onClick={() => {*/}
        {/*        const sections = isProvider*/}
        {/*          ? ['providerPickup']*/}
        {/*          : ['exterior', 'selfieWithId', 'interior', 'odometer', 'fuel'];*/}
        {/*        let currentPhotos = [];*/}
        {/*        activePhotoSection.map((section, index) => {*/}
        {/*          if (section === true) {*/}
        {/*            if (sections[index] === 'interior') {*/}
        {/*              currentPhotos = [...currentPhotos, interiorPhotos];*/}
        {/*            }*/}
        {/*            if (sections[index] === 'odometer') {*/}
        {/*              currentPhotos = [...currentPhotos, odometerPhotos];*/}
        {/*            }*/}
        {/*            if (sections[index] === 'fuel') {*/}
        {/*              currentPhotos = [...currentPhotos, fuelGaugePhotos];*/}
        {/*            }*/}
        {/*          }*/}
        {/*        });*/}
        {/*        onUploadInteriorPhotoToMetadata(transaction.id, [...currentPhotos]);*/}
        {/*        shouldShowPickUpSection(false);*/}
        {/*        shouldShowDropOffSection(false);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <FormattedMessage id="TransactionPanel.sendInteriorPhotos" />*/}
        {/*    </PrimaryButton>*/}
        {/*  </div>*/}
        {/*) : null}*/}

        { (customerPickUpAction || customerDropOffAction) && !isNewPickUpDropSection ? (
          <>
            <div
              className={`${css.buttonSectionWrapper} ${isLimitedUser ? css.isLimitedUserWrapper : ''}`}>
              <PrimaryButton
                id={'submitButtonId'}
                className={css.buttonAccepted}
                type="submit"
                inProgress={transitInProgress}
                disabled={
                  transitInProgress ||
                  !validPhotoSection ||
                  (isLimitedUser && isPickUp && !isPickUpByAdminButtonEnabled)
                }
                onClick={() => isLockboxActive ? handleLockBoxCar() : handlePrimaryButtonClick(transaction, ownListing)}
              >
                <FormattedMessage
                  id={isLimitedUser && transitionAdminMaybe ? getTransitButtonLabelId(transitionAdminMaybe) : getTransitButtonLabelId(transitionMaybe)}/>
              </PrimaryButton>
            </div>
            {showAlert && (
              <AlertBox
                title="Input error"
                message={alertMessage}
                type="error"
                autoCloseTime={2000}
              />
            )}
          </>
        ) : null}

        { (providerPickUpAction || providerDropOffAction) ? (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              id={'submitButtonId'}
              className={css.buttonAccepted}
              type="submit"
              inProgress={transitInProgress}
              disabled={transitInProgress || !isActivePhotoSection}
              onClick={() => {
                handlePrimaryButtonClick(transaction, ownListing);
              }}
            >
              <FormattedMessage id={isLimitedUser && transitionAdminMaybe ? getTransitButtonLabelId(transitionAdminMaybe) : getTransitButtonLabelId(transitionMaybe)} />
            </PrimaryButton>
          </div>
        ) : null}

       { (showViewDetails &&  !isLimitedUser) || isCustomer && showStartTripButton ? (
          <>
          <div className={`${css.buttonSectionWrapper} ${css.footerContainerWrapper}`}>
           {!showViewDetails ? <div className={css.tripSliderWrapper}>
              <TripInstructionsSlider currentUser={currentUser} />
            </div> : <div className={css.tripSliderWrapper}>
            </div>}
            <div>
              <PrimaryButton
                id={'submitButtonId'}
                className={`${css.buttonAccepted} ${css.footerButton} ${css.disabled}`}
                type="submit"
                inProgress={transitInProgress}
                disabled={
                  !(showEndTrip ||
                    // Pickup logic
                    (isPickUp &&
                      !showViewDetails &&
                      (
                        // If requirePickupLocation is true or does not exist, check distance
                        (requirePickupLocation !== false && distanceFromPickUp?.distance <= 250 && isTimeToShowStartTripButton && isUserLocationAvailable) ||
                        // If requirePickupLocation is false, skip distance check, but check the time condition
                        (requirePickupLocation === false && isTimeToShowStartTripButton && isUserLocationAvailable)
                      )
                    ) ||
                    // Dropoff logic
                    (isDropOff &&
                      !showEndTrip &&
                      (
                        // If requireDropoffLocation is true or does not exist, check distance
                        (requireDropoffLocation !== false && distanceFromPickUp?.distance <= 500 && isUserLocationAvailable) ||
                        // If requireDropoffLocation is false, skip distance check, but check the time condition
                        (requireDropoffLocation === false && isTimeToShowStartTripButton && isUserLocationAvailable)
                      )
                    )
                  )
                }
                onClick={() => {
                  if (showViewDetails) {
                    setShowViewDetails();
                    shouldShowPickUpSection(true);
                  } else {
                    showStartTripStep(true);
                    dispatch(resetSteps());
                  }
                }}
              >
                {isPickUp ? (showViewDetails ? "View details" : 'Start pickup') : 'Start dropoff'}
              </PrimaryButton>
            </div>
          </div>
          </>
        ) : null}

        { isNewPickUpDropSection ? (
          <div className={`${css.buttonSectionWrapper} ${css.buttonAlignment}`}>
            <PrimaryButton
              id={'submitButtonId'}
              className={`${css.buttonAccepted} ${css.pickAndDropBtn}` }
              type="submit"
              inProgress={transitInProgress}
              disabled={isPickUp && !currentRating}
              onClick={() => { // Call fuel receipt func here
                handlePrimaryButtonClick(transaction, ownListing);
                if (!isPickUp){
                  submitFuelReceipt();
                }
              }}
            >
              { isPickUp ? 'Complete pickup' : 'Complete dropoff' }
            </PrimaryButton>
          </div>
        ) : null}
      </div>

      {isLockboxActive &&
        <DropOffCheckList
        isOpen={showDropOffChecklist}
        onClose={() => setShowDropOffChecklist(false)}
        cancelButtonId={'dropOffChecklistCancel'}
        onConfirm={() => {
          setShowDropOffChecklist(false);
          handlePrimaryButtonClick(transaction, ownListing);
        }}
        lockBoxCode={lockBoxCode}
      />}

    { isLockboxActive && showPickUpModal &&
      <LockBoxSection
        listing = {listing}
        isPikUp = {true}
        findDeviceByType = {findDeviceByType}
        isOpenModal={showPickUpModal}
        onClose={() => setShowPickUpModal(false)}
        cancelButtonId={'dropOffChecklistCancel'}
        onConfirm={() => {
          setShowPickUpModal(false);
          handlePrimaryButtonClick(transaction, ownListing);
        }
      }
        onLockboxDataChange={onLockboxDataChange}
      />}
    </div>
  );
};

export default ButtonsSection


