import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import css from './TripReceipt.css';

const TripReceipt = () => {
  const { id } = useParams();
  const [tripData, setTripData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        console.log('Fetching Data...');
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/stripe/receipt/${id}`
        );
        const data = await response.json();
        console.log('Fetched Data:', data);
        setTripData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    if (id) {
      fetchTripData();
    }
  }, [id]);

  const handlePrint = () => {
    window.print();
  };

  if (loading) return <p>Loading receipt...</p>;
  if (error) return <p>Error: {error}</p>;

  const {
    listingTitle = 'Unknown Listing',
    tripReferenceCode = 'N/A',
    startDateTime = null,
    endDateTime = null,
    bookedOn = null,
    durationLineItems = [],
    distanceLineItems = [],
    durationPaymentDetails = [],
    distancePaymentDetails = [],
    totalDurationCharge = 0,
    totalDistanceCharge = 0,
    grandTotal = 0,
  } = tripData ?? {};

  // Helper function to format payment date
  const formatPaymentDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  // Helper function to render payment details
  const renderPaymentDetails = (paymentDetails) => {
    return paymentDetails.map((payment, index) => (
      <div key={index} className={css.paymentBox}>
        <div className={css.paymentHeader}>You paid</div>
        <div className={css.paymentContent}>
          <div className={css.cardDetails}>
            {payment.card.brand.toUpperCase()} **** **** **** {payment.card.last4}{' '}
            on {formatPaymentDate(payment.paymentDate)}
          </div>
          <div className={css.amount}>${payment.amount.value.toFixed(2)}</div>
        </div>
      </div>
    ));
  };

  return (
    <div className={css.tripreceipt}>
      {/* Header Section */}
      <div className={css.header}>
        <div className={css.headerLeft}>
          <div className={css.logoSection}>
            <img
              src="https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/logos/drive-mate-footer-logo.png"
              alt="Drive Mate Logo"
              className={css.logo}
            />
          </div>
          <div className={css.contentSection}>
            <h1 className={css.title}>Trip receipt</h1>
            <p className={css.trip}>Trip #{tripReferenceCode}</p>
          </div>
        </div>
        <button onClick={handlePrint} className={css.printButton}>
          Print
        </button>
      </div>
      <hr className={css.divider} />

      {/* Vehicle Info Section */}
      <div className={css.vehicleInfo}>
        <h2>{listingTitle}</h2>
        <p>
          {new Date(startDateTime).toLocaleString()} to {new Date(endDateTime).toLocaleString()}
        </p>
        <p>Booked on {new Date(bookedOn).toLocaleDateString()}</p>
      </div>
      <hr className={css.divider} />

      {/* Hire Cost Section */}
      {durationLineItems.length > 0 && (
        <div className={css.chargesSection}>
          <h3>Hire Costs</h3>
          {durationLineItems.map((item, index) => (
            <div key={index} className={css.chargeItem}>
              <span className={css.itemName}>{item.description}</span>
              <span className={css.dashedLine}></span>
              <span className={css.itemAmount}>${item.amount}</span>
            </div>
          ))}
          <div className={css.total}>
            <span>Total duration charge</span>
            <span>${totalDurationCharge}</span>
          </div>
          <p className={css.gstNote}>GST included</p>

          {durationPaymentDetails.length > 0 && renderPaymentDetails(durationPaymentDetails)}
        </div>
      )}

      {/* Driving Costs Section */}
      {distanceLineItems.length > 0 && (
        <div className={css.chargesSection}>
          <h3>Driving Costs</h3>
          {distanceLineItems.map((item, index) => (
            <div key={index} className={css.chargeItem}>
              <span className={css.itemName}>
                {item.description}
              </span>
              <span className={css.dashedLine}></span>
              <span className={css.itemAmount}>${item.amount}</span>
            </div>
          ))}
          <div className={css.total}>
            <span>Total trip end charge</span>
            <span>${totalDistanceCharge}</span>
          </div>
          <p className={css.gstNote}>GST included</p>

          {distancePaymentDetails.length > 0 && renderPaymentDetails(distancePaymentDetails)}
        </div>
      )}

      <hr className={css.divider} />
      {/* Final Total Section */}
      <div className={css.finalTotal}>
        <h2>Total</h2>
        <p>${grandTotal}</p>
      </div>
      <hr className={css.divider} />
      {/* Footer Section */}
      <div className={css.footer}>
        <p>
          As on 01.11.24. Subject to change in event of additional fees, refunds, and/or costs
          during or after trip.
        </p>
        <hr className={css.divider} />
        <div className={css.companyInfo}>
          <p>Drive Mate</p>
        </div>

        <address>
          Drive lah Australia Pty Ltd
          <br />
          ABN number: 19640421496
          <br />
          Suite 5, Level 14, 350 Collins Street, Melbourne, Vic 3000
        </address>
      </div>
    </div>
  );
};

export default TripReceipt;