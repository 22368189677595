import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_LATE_RETURN_CHARGE,
  LINE_ITEM_LATE_RETURN_FEE,
  LINE_ITEM_LATE_RETURN_PAYOUT,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_PROCESSING_FEE_ADDONS,
} from '../../util/types';
import Explanation from './Explaination';
import classNames from "classnames";
import css from './BookingBreakdown.css';

const LineItemLateReturn = ({
  transaction,
  isCustomer,
  intl,
  isAddons,
  isTripDetailsPage,
  isExplanationBreakdown,
  lateReturnDuration,
}) => {

  const lateReturnLineItem = isCustomer ? transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_LATE_RETURN_CHARGE && !item.reversal
  ) : transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_LATE_RETURN_PAYOUT && !item.reversal
  );

  const lateReturn =lateReturnLineItem ? lateReturnLineItem.lineTotal : null;

  const formattedLateReturn = lateReturn ? formatMoney(intl, lateReturn, 2) : null;

  return formattedLateReturn ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {isCustomer ? 'Late return charge' : 'Late return payout'}
        {lateReturnDuration ? `(${lateReturnDuration})` : ''}
      </span>
      <span className={classNames(css.itemValue, {[css.explanationValue]: isExplanationBreakdown})}>{formattedLateReturn}</span>
    </div>
  ) : null;
};

export default LineItemLateReturn;
