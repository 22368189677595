import React, { memo } from 'react';
import css from './CarRentalReview.css';
import { Container, Row } from '../../components/Grids/Grid';
import imageSource from '../../util/imageSource';

const CarRentalReview = memo(() => {
  return (
    <div className={css.customerReviewSection}>
      {/* <Container> */}
        <Row className={css.reviewContainer}>
          <img src={imageSource.newMarketingLandingPage.car} alt="car-image" className={css.carImage} />
          <div className={css.reviewContent}>
            <div className={css.stars}>
              {[...Array(5)].map((_, index) => (
                <img key={index} src={imageSource.newMarketingLandingPage.yellowStar} alt="star-image" className={css.star} />
              ))}
            </div>
            <p className={css.reviewText}>
              “Easiest car rental. Rented a car on the Gold Coast for a week and it went great. This
              is the third time I've used them because its good value.”
            </p>
            <p className={css.reviewAuthor}>James Forrest</p>
          </div>
        </Row>
      {/* </Container> */}
    </div>
  );
})

export default CarRentalReview;
