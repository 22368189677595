import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import css from './InstantCarRentalSection.css';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import { CopyToClipboard } from '../../components';
import StarPromotionIcon from '../../components/Icons/StarPromotionIcon';
import config from '../../config';
import BLandingCss from '../LandingPageNew/LandingPageNew.css';
import CopyClipboard from '../../assets/copy-clipboard.svg';
import imageSource from '../../util/imageSource';

const { Money } = types;

const InstantCarRentalSection = memo(({ signUpCodeAmount, codeType, signUpCode, codeAuthor, openSignUpModal, ...props }) => {

  const intl = useIntl();
  const amount =
    signUpCodeAmount && formatMoney(intl, new Money(signUpCodeAmount, config.currency));

  const textBasedOnCodeType =
    codeType === 'signUpCode' ?
      <span>Signup & get free credits worth <strong>{amount}</strong> </span> :
      codeType === 'flatOffCode' ?
        amount && signUpCode && (
          <span>Get flat <strong>{amount}</strong> off on your first booking! Use {signUpCode} during checkout.</span>) :
        codeType === 'percentOffCode' ?
          amount && signUpCode && (
            <span>Get <strong>{(signUpCodeAmount/100)}</strong>% off on your first booking! Use {signUpCode} during checkout.</span>) :
          codeType === 'referralCode' ?
            codeAuthor && (<span>Referral code from <strong>{codeAuthor}</strong> </span>) : '';

  const promoContainer = textBasedOnCodeType && (
    <div className={css.info}>
      <StarPromotionIcon className={css.startPercentage} />
      <span style={{marginRight: "5px"}}>{textBasedOnCodeType}</span>
      <img src={CopyClipboard} alt={'copy'} style={{marginRight: "5px"}}/>
    </div>
  );

  const copyToClipboard = promoContainer && <CopyToClipboard
    text={promoContainer}
    textToCopy={signUpCode}
    hightlightText={'Asd'}
    className={BLandingCss.copyReferralCode}
  /> || '';
  return (
    <div className={css.instantCarRetalSection}>
      {/* <Container> */}
        {/* <Row> */}
          <div className={css.container}>
            <div className={css.content}>
              <div className={css.textSection}>
                <h2 className={css.title}>
                  <span>  Instant Cars </span>
                 <span className={css.highlight}>Near You</span>
                </h2>
                <p className={css.description}>
                Rent local cars hourly, daily, weekly or more. Simply search, book, and drive today.
                </p>
              </div>
              <div style={{width:'100%', display:'flex',flexDirection:'column',gap:'8px'}} >
               {signUpCode && <div className={css.clipboardContainer}  >
                  {copyToClipboard}
                </div>}
                <button onClick={openSignUpModal} className={css.signupBtn}>
                <img src={imageSource.newMarketingLandingPage.gift} alt="gift-box-image" className={css.giftBox} />
                <span className={css.btnText}>Sign Up now</span>
                </button>
              </div>
              <div className={css.promo}>
                <img src={imageSource.newMarketingLandingPage.giftWhite} alt="gift-box-image" className={css.promoGiftBox} />
                <span className={css.promoText}>Sign up now and get $10 free credit!</span>
              </div>
            </div>

            <div className={css.instantCarImage}>
            </div>
          </div>
        {/* </Row> */}
      {/* </Container> */}
    </div>
  );
});

export default InstantCarRentalSection;
