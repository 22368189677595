import React, { memo, useEffect, useState } from 'react';
import css from './FAQ.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import axios from 'axios';
import imageSource from '../../util/imageSource';
require('dotenv').config();

const faqData = [
  {
    question: `How do I pick up the car once I’ve booked?`,
    answer: `Once your booking is confirmed, you’ll receive clear instructions on where and how to pick up the car. Many cars are conveniently equipped with features like App enabled keyless access or lockboxes, so you can enjoy a smooth, hassle-free pickup process at the designated location.`,
  },
  {
    question: `Is there a deposit required?`,
    answer: `You'll pay a small $50 upfront deposit which is 100% refundable! Simply book your car and enjoy peace of mind during your trip.`,
  },
  {
    question: `What is included in the price?`,
    answer: `The price you see covers the car rental and any applicable taxes. You’ll only need to cover extras like distance and tolls, making it easy to budget for your trip without hidden surprises.`,
  },
  {
    question: `What happens if I damage the car during a booking?`,
    answer: `Accidents happen, and we’ve got you covered! If damage occurs, we’ll guide you through the process. You’ll only be responsible for up to a capped amount (the Damage Excess), and our team will work with you to resolve it quickly and fairly.`,
  },
];

const FAQ = memo(() => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [faq, setFaq] = useState([]);

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const pushFAQData = async () => {
    try {
      for (let i = 0; i < faqData.length; i++) {
        const faq = faqData[i];
        const payload = {
          title: faq.question,
          description: `<p>${faq.answer}</p>`,
          sequenceNo: i + 5,
          isActive: true,
        };

        console.log('payload: ', payload);

        // API call to push FAQ data
        const response = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/api/faq`,
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        console.log(`FAQ ${i + 1} pushed successfully`, response.data);
      }
    } catch (error) {
      console.error('Error while pushing FAQ data:', error.message);
    }
  };

  const getFAQData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/faq`);
      console.log('response: ', response.data);
      const filteredFAQs = response.data
        .filter(item => item.sequenceNo >= 5 && item.sequenceNo <= 8)
        .map(item => ({
          question: item.title,
          answer: item.description,
        }));

      setFaq(filteredFAQs);
    } catch (error) {
      console.error('Error while pushing FAQ data:', error.message);
    }
  };

  // Automatically push FAQ data when the component loads
  useEffect(() => {
    // pushFAQData();
    getFAQData();
  }, []);

  return (
    <div className={css.faqSection}>
      {/* <Container> */}
      <Row className={css.sectionTitle}>
        <Column large={12}>
          <h2 className={css.faqHeading}>Frequently Asked Questions</h2>
        </Column>
      </Row>
      <Row className={css.question}>
        <Column large={12}>
          <div className={css.faqGrid}>
            {faq.map((faq, index) => (
              <div
                key={index}
                className={`${css.faqItem} ${activeIndex === index ? 'css.active' : ''}`}
                onClick={() => toggleFAQ(index)}
                style={{maxHeight: "fit-content"}}
              >
                <div className={css.faqQuestion}>
                  <h4>{faq.question}</h4>
                  <img
                    src={imageSource.newMarketingLandingPage.chevronRight}
                    alt={activeIndex === index ? 'up-arrow' : 'down-arrow'}
                    style={{
                      transform: activeIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease',
                    }}
                  />
                </div>
                {activeIndex === index && (
                  <hr
                    style={{
                      background: '#E8E8E8',
                      height: '1px',
                      width: '100%',
                      margin: '4px 0px',
                    }}
                  />
                )}
                <div
                  className={css.faqAnswer}
                  style={{
                    maxHeight: activeIndex === index ? '200px' : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease-out',
                  }}
                >
                  <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                </div>
              </div>
            ))}
          </div>
        </Column>
      </Row>
      {/* </Container> */}
    </div>
  );
});

export default FAQ;
