import React, { useEffect, useState } from 'react';
import styles from './CustomBreakdown.module.css';

const CustomBreakdown = ({ childDistanceTransactionJSON, distanceChargeJSON, lateReturnJSON, layout, onTotalChange, customTotalTitle }) => {

  const breakdownConfiguration = {
    distanceChargePage: [
      { label: `Distance charge (${distanceChargeJSON?.distanceKmFee} x ${(distanceChargeJSON?.distance) / 1000} km)`, value: +(distanceChargeJSON?.distanceCharge?.toFixed(2)), display: `$${distanceChargeJSON?.distanceCharge?.toFixed(2)}` },
      { label: `Late return charge (${lateReturnJSON?.lateReturnDurationReadable})`, value: +(lateReturnJSON?.lateReturnCharge?.toFixed(2)), display: `$${lateReturnJSON?.lateReturnCharge?.toFixed(2)}` },
      { label: 'Inconvenience charge', value: +(lateReturnJSON?.inconvenienceCharge?.toFixed(2)), display: `$${lateReturnJSON?.inconvenienceCharge?.toFixed(2)}` },
      { label: 'Processing fee', value: +(distanceChargeJSON?.processingFee?.toFixed(2)), display: `$${distanceChargeJSON?.processingFee?.toFixed(2)}` },
    ],
    guestBreakdown1: [
      { label: `Distance charge (${distanceChargeJSON?.distanceKmFee} x ${(distanceChargeJSON?.distance) / 1000} km)`, value: +(distanceChargeJSON?.distanceCharge?.toFixed(2)), display: `$${distanceChargeJSON?.distanceCharge?.toFixed(2)}` },
      { label: 'Processing fee', value: +(distanceChargeJSON?.processingFee?.toFixed(2)), display: `$${distanceChargeJSON?.processingFee?.toFixed(2)}` },
    ],
    guestBreakdown2: [
      { label: `Late return charge (${lateReturnJSON?.lateReturnDurationReadable})`, value: +(lateReturnJSON?.lateReturnCharge?.toFixed(2)), display: `$${lateReturnJSON?.lateReturnCharge?.toFixed(2)}` },
      { label: 'Inconvenience charge', value: +(lateReturnJSON?.inconvenienceCharge?.toFixed(2)), display: `$${lateReturnJSON?.inconvenienceCharge?.toFixed(2)}` },
    ],
    hostBreakdown1: [
      { label: `Distance charge (${distanceChargeJSON?.distanceKmFee} x ${(distanceChargeJSON?.distance) / 1000} km)`, value: +(distanceChargeJSON?.distanceCharge?.toFixed(2)), display: `$${distanceChargeJSON?.distanceCharge?.toFixed(2)}` },
      { label: 'Distance fee', value: +(-(distanceChargeJSON?.distanceDM)?.toFixed(2)), display: `-$${+(distanceChargeJSON?.distanceDM?.toFixed(2))}` },
    ],
    hostBreakdown2: [
      { label: `Late return payout (${lateReturnJSON?.lateReturnDurationReadable})`, value: +(lateReturnJSON?.lateReturnHostPayout?.toFixed(2)), display: `$${lateReturnJSON?.lateReturnHostPayout?.toFixed(2)}` },
      { label: 'Inconvenience payout', value: +(lateReturnJSON?.inconveniencePayout?.toFixed(2)), display: `$${lateReturnJSON?.inconveniencePayout?.toFixed(2)}` },
    ],
  };  

  const breakdownItems = breakdownConfiguration[layout] || [];
  const total = +(breakdownItems.reduce((sum, item) => sum + (item.value || 0), 0)).toFixed(2);

  useEffect(() => {
    if (onTotalChange) {
      onTotalChange(total);
    }
  }, [total, onTotalChange]);

  return (
    <div className={styles.breakdown}>
      {breakdownItems.length > 0 && breakdownItems.map(ele => (
        <>
          {ele.value && (<div className={styles.lineItem}>
            <span className={styles.itemLabel}>
              {ele.label}
            </span>
            <span className={styles.itemValue}>{`${ele.display}`}</span>
          </div>)}
        </>
      ))}
      <hr />
      <div className={styles.lineItem}>
        <span className={styles.itemLabel} style={{ color: '#026786', fontSize: '16px' }}>
          {customTotalTitle ? customTotalTitle : `Total due now`}
        </span>
        <span className={styles.itemValue} style={{ color: '#026786' }}>{`$${total}`}</span>
      </div>
    </div>
  );
};

export default CustomBreakdown;