import React, { memo, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { searchListings } from '../SearchPage/SearchPage.duck';
import { getSuburbForListing, getShortStateForListing } from '../../util/helpers';
import css from './CarListingSection.css';
import classNames from 'classnames';
import imageSource from '../../util/imageSource';
import { denormalisedResponseEntities, ensureListing } from '../../util/data';
import { NamedLink, ResponsiveImage } from '../../components';
import { createSlug } from '../../util/urlHelpers';
import Slider from 'react-slick';
import NoImageIcon from '../../components/ResponsiveImage/NoImageIcon';
import { FormattedMessage } from 'react-intl';

const CarListingSection = memo(({ openSignUpModal }) => {
  const [activeLocation, setActiveLocation] = useState(0);
  const [results, setResults] = useState([]);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 960);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const locations = useMemo(
    () => [
      { name: 'Sydney, NSW', label: 'New South Wales' },
      { name: 'Melbourne, VIC', label: 'Victoria' },
      { name: 'Brisbane, QLD', label: 'Queensland' },
      { name: 'WA / SA', label: 'WA / SA' },
    ],
    []
  );

  const locationSearchParams = {
    'Sydney, NSW': {
      address: 'Sydney NSW, Australia',
      bounds: {
        ne: { lat: -33.41966205794024, lng: 151.750246034722 },
        sw: { lat: -34.31797734205976, lng: 150.66834496527798 },
      },
      timezone: 'Australia/Sydney',
    },
    'Melbourne, VIC': {
      address: 'Melbourne VIC, Australia',
      bounds: {
        ne: { lat: -37.36446995794024, lng: 145.53160845475844 },
        sw: { lat: -38.262785242059756, lng: 144.39450674524159 },
      },
      timezone: 'Australia/Melbourne',
    },
    'Brisbane, QLD': {
      address: 'Brisbane QLD, Australia',
      bounds: {
        ne: { lat: -27.02129515794024, lng: 153.5322718845505 },
        sw: { lat: -27.91961044205976, lng: 152.5197963154495 },
      },
      timezone: 'Australia/Brisbane',
    },
    'WA / SA': {
      perth: {
        address: 'Perth WA, Australia',
        bounds: {
          ne: { lat: -31.50224165794024, lng: 116.39103701034684 },
          sw: { lat: -32.40055694205976, lng: 115.33231958965315 },
        },
        timezone: 'Australia/Perth',
      },
      adelaide: {
        address: 'Adelaide SA, Australia',
        bounds: {
          ne: { lat: -34.47934125794024, lng: 139.14859028950332 },
          sw: { lat: -35.37765654205976, lng: 138.0529009104967 },
        },
        timezone: 'Australia/Adelaide',
      },
    },
  };

  const getFormattedDate = date => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const today = new Date();
  const startDate = getFormattedDate(today);
  const endDate = getFormattedDate(new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000));

  const fetchListings = async () => {
    const selectedLocation = locations[activeLocation]?.name;
    const params = locationSearchParams[selectedLocation];

    try {
      let response;
      if (selectedLocation === 'WA / SA') {
        const perthResponse = await dispatch(
          searchListings({
            ...params.perth,
            distance: 15000,
            page: 1,
            perPage:  windowWidth <= 960 ? 1 : 3,
            dates: `${startDate},${endDate}`,
            hours: '09:00,09:00',
            include: ['author', 'images'],
            'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
            'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
            'limit.images': 1,
          })
        );

        const adelaideResponse = await dispatch(
          searchListings({
            ...params.adelaide,
            distance: 15000,
            page: 1,
            perPage: 1,
            dates: `${startDate},${endDate}`,
            hours: '09:00,09:00',
            include: ['author', 'images'],
            'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
            'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
            'limit.images': 1,
          })
        );

        const perthListings = perthResponse?.data?.data || [];
        const adelaideListing = adelaideResponse?.data?.data || [];

        setResults([...perthListings, ...adelaideListing].slice(0, windowWidth <= 960 ? 2 : 4));
      } else {
        response = await dispatch(
          searchListings({
            address: params.address,
            locationName: params.address,
            bounds: params.bounds,
            timezone: params.timezone,
            distance: 15000,
            page: 1,
            perPage: 100,
            dates: `${startDate},${endDate}`,
            hours: '09:00,09:00',
            include: ['author', 'images'],
            'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
            'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x']
          })
        );
        const denormalisedRes = denormalisedResponseEntities(response);
        console.log(denormalisedRes,"demolres")
        setResults(denormalisedRes ? denormalisedRes.slice(0, windowWidth <= 960 ? 2 : 4) : []);
      }
    } catch (error) {
      console.error('Error fetching listings:', error.message || error);
    }
  };

  useEffect(() => {
    fetchListings();
  }, [activeLocation, windowWidth]);

  useEffect(() => {
    fetchListings();
  }, [dispatch]);



  const handleLocationClick = index => setActiveLocation(index);

  const cars = results.map((result, index) => ({
    id:result.id.uuid,
    slug: createSlug(result.attributes.title),
    isDrivelahGo: result.attributes.metadata.isDrivelahGo ,
    name: result.attributes?.title || `Car ${index + 1}`,
    location: result ? `${getSuburbForListing(result)}, ${getShortStateForListing(result)}` : '',
    price:
      result?.attributes?.price && result?.attributes?.publicData
        ? `${
            result.attributes.price.amount
              ? `$${result.attributes.price.amount / 100}/day`
              : ''
          } ${
            result.attributes.publicData.pricing?.hourlyRegularPrice?.amount
              ? `fraction or $${
                  result.attributes.publicData.pricing.hourlyRegularPrice.amount / 100
                }/hour`
              : ''
          } ${
            result.attributes.publicData.pricing?.distanceKm
              ? `+ $${result.attributes.publicData.pricing.distanceKm}/km`
              : ''
          }`
        : '',
    rating:
      result?.attributes?.metadata?.reviews?.averageRating,
    trips:
      result?.attributes?.publicData?.numberTripDone
        ? `${result.attributes.publicData.numberTripDone}`
        : '',
   transmissions: result.attributes.publicData.transmissions[0],
    images: ensureListing(result).images,
    image: ensureListing(result).images[0],
  }));

  function capitalizeFirstLetter(str) {
    if (!str) return ''; // Handle empty or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

 



  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <p>next</p> ,
    prevArrow: <p>prevArrow</p> 
  };

  function appendDots(dots) {
    return (
      
        <ul style={{ margin: "3px" }}> {dots} </ul>
      
    );
  }

  return (
    <div className={css.carListingSection}>
      <h1 className={css.heading}>Discover the best cars in your neighbourhood</h1>
      <div className={classNames(css.grid)}>
        <div className={css.locations}>
          {locations.map((location, index) => (
            <div
              key={index}
              className={`${css.location} ${activeLocation === index ? css.activeLocation : ''}`}
              onClick={() => handleLocationClick(index)}
            >
              <p className={css.locationName}>{ windowWidth<=960 ? location.label : location.name}</p>
              {/* <p className={css.locationCars}>{results.length} Cars</p> */}
            </div>
          ))}
        </div>

        <div className={css.carList}>
          {cars.map((car, index) => (
            <NamedLink key={index} className={css.carCard} name={car.isDrivelahGo ? 'ListingPageGo' : 'ListingPage'} params={ {id:car.id ,slug: car.slug}}>
        
             



 <div className={css.imgWrapper}>
                    <div   className={css.imgContainer}></div>

                   
                      {car.images[0] && car.images[0].attributes ? (
                        <Slider style={{height:'230px', overflox:'hidden'}} {...settings} className={css.slider}  
                        appendDots={appendDots} >
                          {car &&
                            car.images.length > 0 &&
                            car.images.map((image,indes) => {
                              return (
                                <ResponsiveImage
                                key={index}
    rootClassName={css.cardRootForImage}
    alt={car.name}
    image={image}
    variants={['landscape-crop', 'landscape-crop2x']}
  />
                              );
                            })}
                        </Slider>
                      ) : (
                        
                        <div className={css.noImageDisplayContainer}>
                          <div className={css.noImageContainer}>
                    
                          <div className={css.noImageWrapper}>
                    
                              <NoImageIcon />
                              <div className={css.noImageText}>
                                <FormattedMessage id="ResponsiveImage.noImage" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>


              <div className={css.carDetails}>
                <div className={css.carInfo}>
                  <div className={css.carNameSection}>
                    <h3 className={css.carName}>{car.name}</h3>
                    <p className={css.carLocation}>{car.location}, {capitalizeFirstLetter(car.transmissions)}</p>
                  </div>

                  <div className={css.carRating}>
                    {car.rating !== undefined && (
                      <img src={imageSource.newMarketingLandingPage.star} alt="star-image" className={css.starImage} />
                    )}
                    <div className={css.ratingText}>
                      <p>{car.rating !== undefined && `${car.rating}`}</p>
                      <p style={{ fontWeight: '300' }}>
                        {car.trips && car.trips.length > 0 ? `(${car.trips.length} ${car.trips.length <2?'trip':'trips'})` : ''}
                      </p>
                    </div>
                  </div>
                </div>
                <p className={css.carPrice}>
                  <span>{car.price.split('fraction')[0]}</span>
                  <span style={{ opacity: 0.6 }}>{car.price.split('fraction')[1]}</span>
                </p>
              </div>
         
            </NamedLink>
          ))}
        </div>
      </div>

      <div className={classNames(css.btnWrapper, css.showDesktop)}>
        <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
          <img src={imageSource.newMarketingLandingPage.gift} alt="gift" /> Sign Up and Get $10 Free Credit
        </button>
      </div>
      <div className={classNames(css.btnWrapper, css.showMobile)}>
        <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
          <img src={imageSource.newMarketingLandingPage.gift} alt="gift" />
          Sign Up and Get $10 Free Credit
        </button>
      </div>
    </div>
  );
});

export default CarListingSection;